// import react components or files
import React, { useState } from "react";
import ViewDetails from "./ViewDetails";
// import libraries
import { Link } from "react-router-dom";

//import styles
import "./librarycards.scss";
import ShowFloderIcon from "../../ManageTest/TestBundle/TestBundle";

const LibraryCards = ({ data }) => {
  const [modalData, setModalData] = useState({ modal: false, item: null });

  const showDetails = (card) => {
    setModalData({ modal: true, item: card });
  };

  const renderedCards = data.map((card, i) => (
    <div key={i} className={`testlibrarycard`}>
      <header className="testlibrarycard_header">
        <p>{card.name}</p>
        <ShowFloderIcon isInBundle={card.isinbundle} title={(card?.bundle_details !== undefined && card?.bundle_details != null) ? card?.bundle_details?.map(item=>{
          return  item?.title
          })?.join(' and ') : ''}
          index={i} bundleDetails={card?.bundle_details} />
      </header>
      <header className="testlibrarycard_subheader">Track: {card.track}</header>
      <div className="testlibrarycard_time_ques">
        <span className="testlibrarycard_time">{card.duration} Minutes</span>
        <span className="testlibrarycard_ques">{card.noofexids} Exercises</span>
      </div>
      <div className="testlibrarycard_footer_buttons">
        <button
          className="button link-button small"
          onClick={(e) => showDetails(card)}
        >
          Details
        </button>
        <Link
          className="button primary small"
          onClick={() => sessionStorage.setItem("plibcheck", 1)}
          to={`/scheduletest/${card.testid}?from=testlibrary`}
        >
          Schedule
        </Link>
      </div>
    </div>
  ));

  return (
    <section className="testlibrarycards_main">
      {data.length <= 0 ? (
        <label
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No matching test template found!
        </label>
      ) : (
        <section className="testlibrarycards_main_grid">
          {renderedCards}
        </section>
      )}
      {modalData.modal && (
        <ViewDetails modalData={modalData} setModalData={setModalData} />
      )}
    </section>
  );
};

export default LibraryCards;
